import axios from 'axios'
import apikeyHeader from './apikey-headers'
import { TUTORIALS } from './urls'

class TutorialsService {
  getTutorials(){
    const url = `${TUTORIALS()}/`
    const config = {
      url: url,
      headers: apikeyHeader(),
    }
    return axios(config)
  }
  getTutorialDetail(id = 0){
    const url = `${TUTORIALS()}/${id}/`
    const config = {
      url: url,
      headers: apikeyHeader(),
    }
    return axios(config)
  }
  sendQuestionAnswer(id = 0){
    const url = `${TUTORIALS()}/respuesta/${id}/`
    const config = {
      url: url,
      headers: apikeyHeader(),
      method: 'POST'
    }
    return axios(config)
  }
}

export default new TutorialsService()