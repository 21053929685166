import axios from 'axios'
import apikeyHeader from './apikey-headers'
import { EVENTS } from './urls'
class EventsServices {
  getEvents(){
    const url = `${EVENTS()}/`
    const config = {
      url,
      headers: apikeyHeader(),
    }
    return axios(config)
  }
  setAttendance(payload = {}){
    const url = `${EVENTS()}/${payload.id}/${payload.action}/`
    const config = {
      url,
      headers: apikeyHeader(),
      method: payload.method
    }
    return axios(config)
  }
}

export default new EventsServices()