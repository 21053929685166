import { Notify } from 'quasar'
import { mdiAlertCircleOutline, mdiCheckCircleOutline } from '@quasar/extras/mdi-v5'

Notify.setDefaults({
  textColor: 'white',
  position: 'top',
  timeout: 3000
})

const sendNotification = (data = {message: '', type: 'success'}) => {
  Notify.create({
    message: data.message,
    icon: data.type === 'success' ? mdiCheckCircleOutline : mdiAlertCircleOutline,
    classes: data.type === 'success' ? 'bg-hulk-green' : 'bg-ironman-red'
  })
}

export default {
  sendNotification
}
