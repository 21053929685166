import axios from 'axios'
import authHeader from './auth-header'
import { BASE_API_SASS } from './config'
import { V1, WORKSHIFTS } from './urls'  

class WorkshiftsService {
  getMarks(queryParams){
    return axios.get(`${V1()}/mark/`, 
      { 
        withCredentials: true,
        headers: authHeader(),
        params: queryParams 
      }
    )
  }
  getShifts(){
    return axios.get(`${V1()}/v2/asistencia/workshifts/get-work-shifts/`,
      {
        withCredentials: true,
        headers: authHeader()
      }
    )
  }
  getMarksByDay(params = {}){
    const url = `${BASE_API_SASS()}/api/v2/asistencia/marks/get-by-day/`
    const config = {
      method: 'GET',
      url: url,
      withCredentials: true,
      headers: authHeader(),
      params: params
    }
    return axios(config)
  }
  getWorkshiftsSummaryList(params = {}){
    const url = `${WORKSHIFTS()}/jornadas/history/`
    const config = {
      url,
      withCredentials: true,
      headers: authHeader(),
      params
    }
    return axios(config)
  }
  modifyWorkshift(data = {}){
    const url = `${WORKSHIFTS()}/jornadas/accept_change/`
    const config = {
      url,
      withCredentials: true,
      headers: authHeader(),
      data,
      method: 'POST'
    }
    return axios(config)
  }
}

export default new WorkshiftsService()