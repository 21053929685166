import { BASE_API_SASS, BASE_API_URL, API_MKP } from './config'

const country = () => {
  const profile = JSON.parse(localStorage.getItem('profile'))
  return profile?.empresaDetails?.pais || 'CL'
}

const serverShortCode = () => {
  return localStorage.getItem('SHORT_CODE') || country()
}

export const BASE_API = () => `${BASE_API_URL()}`
export const AUTH = () => `${BASE_API_URL()}/auth/login`
export const V1 = () => `${BASE_API_URL()}/v1`
export const NOTIFICATIONS = () => `${API_MKP}/notifications/${serverShortCode()}`
export const DSIGN = () => `${BASE_API_SASS()}/es/dsign`

export const PAYROLL = () => `${BASE_API_SASS()}/api/v2/payroll`
export const DASHBOARD = () => `${PAYROLL()}/dashboard`
export const EMPLOYEE = () => `${PAYROLL()}/employee`

export const HOLIDAYS = () => `${V1()}/vacacionesSolicitud`
export const ADMINISTRATIVE = () => `${V1()}/diaAdministrativoSolicitud`
export const PERMISSIONS = () => `${V1()}/permissions`
export const EXTRA_HOURS = () => `${V1()}/extraHours`
export const NEW_EXTRA_HOURS = () => `${V1()}/new/extraHours`

export const HELPDESK = () => `${BASE_API_URL()}/helpdesk/v1`
export const QUEUES = () => `${HELPDESK()}/queues`
export const TICKETS = () => `${HELPDESK()}/tickets`
export const MANAGER = () => `${HELPDESK()}/manager`
export const WORKFLOWS = () => `${HELPDESK()}/workflows`

export const NEXT = () => `${BASE_API_URL()}/talana-next/api`
export const BENEFITS = () => `${API_MKP}/beneficios/api/v1/${serverShortCode()}/beneficios`
export const EVENTS = () => `${POSTS()}/eventos`
export const POSTS = () => `${NEXT()}/contenido`
export const TUTORIALS = () => `${NEXT()}/capacitaciones`

export const TALENT = () => `${BASE_API_SASS()}/api/v2/talent`
export const WORKSHIFTS = () => `${BASE_API_SASS()}/api/asistencia/v2`