import axios from 'axios'
import authHeader from './auth-header'
import apikeyHeader from './apikey-headers'
import { NEXT, TALENT } from './urls'

class ClimateService{
  getCurrentClimatePollURL(id = null){
    const url = `${NEXT()}/polls/answers/${id}/`
    const config = {
      url: url,
      headers: apikeyHeader()
    }
    return axios(config)
  }
  // Objectives
  getPerformanceEvaluations(){
    const url = `${TALENT()}/user-active-objective-evaluations/`
    const config = {
      url: url,
      withCredentials: true,
      headers: authHeader()
    }
    return axios(config)
  }
  getObjectivesEvaluationUsers(id = null){
    const url = `${TALENT()}/evaluation/${id}/evaluator-active-objective-reviews/`
    const config = {
      url: url,
      withCredentials: true,
      headers: authHeader()
    }
    return axios(config)
  }
  getEvaluatedObjectiveReview(id = null){
    const url = `${TALENT()}/evaluation/${id}/evaluated-active-objective-reviews/`
    const config = {
      url: url,
      withCredentials: true,
      headers: authHeader()
    }
    return axios(config)
  }
  getEvaluatedObjectiveFeedbackReview(id = null){
    const url = `${TALENT()}/evaluated-objective-review-feedback/${id}`
    const config = {
      url: url,
      withCredentials: true,
      headers: authHeader()
    }
    return axios(config)
  }
  postEvaluatedObjectiveFeedbackReview(payload = {}){
    const url = `${TALENT()}/evaluated-objective-review-feedback/${payload.id}/`
    const config = {
      method: 'PUT',
      url: url,
      withCredentials: true,
      headers: authHeader(),
      data: payload.data
    }
    return axios(config)
  }
  // Skills
  getCompetenciesEvaluations(){
    const url = `${TALENT()}/user-active-skill-evaluations/`
    const config = {
      url: url,
      withCredentials: true,
      headers: authHeader()
    }
    return axios(config)
  }
  getCompetenciesEvaluationUsers(id = null){
    const url = `${TALENT()}/evaluation/${id}/evaluator-active-skill-reviews/`
    const config = {
      url: url,
      withCredentials: true,
      headers: authHeader()
    }
    return axios(config)
  }
  getEvaluatedCompetenciesReview(id = null){
    const url = `${TALENT()}/evaluation/${id}/evaluated-active-skill-reviews/`
    const config = {
      url: url,
      withCredentials: true,
      headers: authHeader()
    }
    return axios(config)
  }
  getEvaluatedCompetenciesFeedbackReview(id = null){
    const url = `${TALENT()}/evaluated-skill-review-feedback/${id}`
    const config = {
      url: url,
      withCredentials: true,
      headers: authHeader()
    }
    return axios(config)
  }
  postEvaluatedCompetenciesFeedbackReview(payload = {}){
    const url = `${TALENT()}/evaluated-skill-review-feedback/${payload.id}/`
    const config = {
      method: 'PUT',
      url: url,
      withCredentials: true,
      headers: authHeader(),
      data: payload.data
    }
    return axios(config)
  }
}

export default new ClimateService
