export default function apikeyHeader() {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user && user.talana_token && user.kong_api_key) {
      return { 
            'apikey': user.kong_api_key
            //'apikey': user.kong_api_key
        }
    } else {
      return {}
    }
  }