import HelpDeskService from '../services/helpdesk.service.js'

const state = {
  inbox: false,
  manager_queues: [],
  inbox_types: {},
  reassign_ticket: {
    show_modal: false,
    ticket_id: null
  },
  manager_modal: {
    id: null,
    type: '',
    show: false
  },
  loading: false
}
const mutations = {
  setInboxAvailable(state){
    state.inbox = true
  },
  setManagerQueues(state, queues){
    state.manager_queues = queues
  },
  setInboxTypes(state, inbox){
    state.inbox_types = inbox
  },
  setManagerModal(state, modal = {}){
    state.manager_modal = modal
  },
  setTicketId(state, id = null){
    if(id && typeof id === 'number'){
      state.reassign_ticket = {
        show_modal: true,
        ticket_id: id
      }
    }else{
      state.reassign_ticket = {
        show_modal: false,
        ticket_id: null
      }
    }
  },
  setLoading(state, loading = false){
    state.loading = loading
  }
}
const actions = {
  async getDetailStep (_, ticketID = null) {
    const {data} = await HelpDeskService.detailStepWorkFlow(ticketID)
    return data
  },
  async getResponseTicketWithWorkflow (_, ticketID = null) {
    const {data} = await HelpDeskService.getResponseTicketWithWorkflow(ticketID)
    return data
  },
  async getAvailableInbox({commit}){
    const data = await HelpDeskService.getInboxAvailable()
    if(data){
      const payload = data.data
      if(payload.delegate || payload.manager){
        commit('setInboxTypes', payload)
        commit('setInboxAvailable')
      }
      return payload
    }
    return false
  },
  async getTicketsbyManager(_, params = {}){
    const data = await HelpDeskService.getTicketsbyManager(params)
    if(data){
      return data.data
    }
    return false
  },
  async deleteTicket(_, id = null){
    const data = await HelpDeskService.deleteTicket(id)
    return data
  },
  async getManagerQueues({commit}){
    const data = await HelpDeskService.getManagerQueues()
    if(data){
      commit('setManagerQueues', data.data)
      return data.data
    }
    return []
  },
  async sendReminder(_, payload = {}){
    const { data } = await HelpDeskService.sendReminder(payload)  
    return data
  },
  async getManagerReport(_, payload = {}){
    const { data } = await HelpDeskService.getManagerReport(payload)
    return data
  },
  async taskStatusReq(_, task_id = ''){
    const { data } = await HelpDeskService.taskStatusReq(task_id)
    return data
  },
  async taskDownloadReq(_, task_id = ''){
    const { data } = await HelpDeskService.taskDownloadReq(task_id)
    return data
  },
  async getTicketManagerDetail(_, id = null){
    const { data } = await HelpDeskService.getTicketManagerDetail(id)
    return data
  },
  async reassignTicket(_, payload = {}){
    const { data } = await HelpDeskService.reassignTicket(payload)
    return data
  },
  toggleReassignModal({commit}, id = null){
    commit('setTicketId', id)
  },
  setModalConfirmation({ commit }, modal = {}){
    commit('setManagerModal', modal)
  },
  toggleLoading({ commit }, loading = false){
    commit('setLoading', loading)
  },
  closeModal({commit}){
    const modal = {
      id: null,
      type: '',
      show: false
    }
    commit('setManagerModal', modal)
  }
}

const getters = {
  hasAvailableInbox: (state) => {
    return state.inbox
  },
  managerQueues: (state) => {
    return state.manager_queues
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}