import Vue from 'vue'

import quasar, { Notify, colors, Loading, BottomSheet } from 'quasar'
import langES from 'quasar/lang/es'
// Import icon libraries
import '@quasar/extras/mdi-v7/mdi-v7.css'
import '@quasar/extras/material-icons/material-icons.css'
// Import Quasar css
import 'quasar/src/css/index.sass'

Vue.use(quasar, {
  lang: langES,
  plugins: { Notify, Loading, BottomSheet },
})

colors.setBrand('primary', '#6344DD')
colors.setBrand('secondary', '#2DFAD2')
colors.setBrand('accent', '#9C27B0')
colors.setBrand('dark', '#1D1D1D')
colors.setBrand('black', '#27273F')
colors.setBrand('positive', '#46C089')
colors.setBrand('negative', '#E90019')
colors.setBrand('info', '#98DAFF')
colors.setBrand('warning', '#F2C037')