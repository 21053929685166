import EventsServices from '../services/events.service'

export const events = {
  namespaced: true,
  actions: {
    async getEvents() {
      const { data } = await EventsServices.getEvents()
      return data
    },
    async setAttendance(_, payload = {}): Promise<boolean> {
      await EventsServices.setAttendance(payload)
      return true
    }
  }
}