import Vue from 'vue'
import messages from '@/i18n'
import VueI18n from 'vue-i18n'

declare module 'vue/types/vue' {
  interface Vue {
    i18n: VueI18n;
  }
}

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'es-CL',
  fallbackLocale: 'es-CL',
  messages
})

export default i18n
