if('serviceWorker' in navigator){
  navigator.serviceWorker.register('./service-worker.js')
    .then(registration => {
      console.log(registration)
    })
    .catch(error => {
      console.log('Error', error)
    })
}

if(window.navigator && navigator.serviceWorker){
  navigator.serviceWorker.getRegistrations()
    .then((registrations) => {
      registrations.forEach(registration => {
        if(registration.scope.endsWith('service-worker.js')){
          return
        }
        registration.unregister()
      })
    })
}