import TutorialsService from '../services/tutorials.service'
import { Tutorial, TutorialsByCategory, TutorialModule } from '../models/tutorials'

export const tutorials = {
  namespaced: true,
  state: {
    tutorials: [] as Tutorial[],
    tutorial: {} as Tutorial,
  },
  mutations: {
    setTutorials(state, categories = []){
      state.tutorials = categories
    },
    setTutorial(state, tutorial = {}){
      state.tutorial = tutorial
    }
  },
  actions: {
    async getTutorials({commit}): Promise<boolean>{
      const { data: { categories } } = await TutorialsService.getTutorials()
      const catFiltered = categories.filter(cat => !!cat.cursos.length)
      const catFormatted: TutorialsByCategory[] = catFiltered.map(cat => new TutorialsByCategory(cat))
      commit('setTutorials', catFormatted)
      return true
    },
    async getTutorialDetail({commit}, id: number = 0): Promise<boolean>{
      const { data } = await TutorialsService.getTutorialDetail(id)
      commit('setTutorial', new Tutorial(data))
      return true
    },
    async sendQuestionAnswer(_, id: number = 0) {
      const { data } = await TutorialsService.sendQuestionAnswer(id)
      return data
    },
  },
  getters: {
    /**
      Sets total time between all tutorial modules
      @param  {TutorialModule[]} modules Tutorial modules
      @return {Number} Tutorial total time
    */
    setTotalTime: () => (modules: TutorialModule[] = []): number => {
      if(modules.length){
        const times = modules.map(module => {
          const time = module.time.split(' ')
          return time.length ? Number(time[0]) : 0
        })
        return times.reduce((a, b) => a + b, 0)
      }
      return 0
    },
    /**
      Returns tutorial approval percentage
      @param  {TutorialModule[]} modules Tutorial modules
      @return {Number} Percentage
    */
    approvalPercentage: () => (modules: TutorialModule[] = []): number => {
      if(modules.length){
        const approval = modules.map(module => module.percentage)
        return Math.round(approval.reduce((a, b) => a + b, 0) / modules.length)
      }
      return 0
    },
    /**
      Returns total number of completed modules
      @param  {TutorialModule[]} modules Tutorial modules
      @return {Number} Total time
    */
    completedModules: () => (modules: TutorialModule[] = []): number => {
      return modules.filter(module => module.percentage).length
    },
    /**
      Returns tutorial progress
      @param  {TutorialModule[]} modules Tutorial modules
      @return {Number} Progress
    */
    tutorialProgress: (state, getters) => (modules: TutorialModule[] = []): number => {
      if(modules.length){
        return getters.completedModules(modules) / modules.length
      }
      return 0
    },
  }
}