export class TutorialModule{
  approved: boolean
  description: string
  id: number
  image: string
  percentage: number
  time: string
  title: string
  tutorialId: number
  cards: ModuleCard[]

  constructor(map) {
    this.approved = map.aprobado
    this.description = map.descripcion
    this.id = map.id
    this.image = map.imagen
    this.percentage = map.porcentaje
    this.time = map.tiempo
    this.title = map.titulo
    this.tutorialId = map.curso
    this.cards = map.tarjetas ? map.tarjetas.map(tarjeta => new ModuleCard(tarjeta)) : []
  }
}

export class ModuleCard{
  description: string
  id: number
  image: string
  media: string
  moduleId: number
  question: ModuleQuestion|object
  type: string
  title: string
  order: number
  video_yt: string
  video_vimeo: string

  constructor(map){
    this.description = map.descripcion
    this.id = map.id
    this.image = map.imagen
    this.media = map.media
    this.moduleId = map.modulo
    this.question = map.pregunta.length ? new ModuleQuestion(map.pregunta[0]) : {}
    this.type = map.tipo
    this.title = map.titulo
    this.order = map.orden
    this.video_yt = map.video_yt
    this.video_vimeo = map.video_vimeo
  }
}

export class Tutorial{
  id: number
  description: string
  image: string
  title: string
  modules: TutorialModule[]

  constructor(map) {
    this.id = map.id
    this.description = map.descripcion
    this.image = map.imagen
    this.title = map.titulo
    this.modules = map.modulos.map(module => new TutorialModule(module))
  }
}

export class TutorialsByCategory{
  id: number
  title: string
  description: string
  tutorials: Tutorial[]

  constructor(map){
    this.id = map.id
    this.title = map.titulo
    this.description = map.descripcion
    this.tutorials = map.cursos.map(tutorial => new Tutorial(tutorial))
  }
}

export class ModuleQuestion{
  id: number
  options: QuestionOption[]
  score: number
  solution: string
  cardId: number
  title: string

  constructor(map){
    this.id = map.id
    this.options = map.opciones.map(option => new QuestionOption(option))
    this.score = map.puntaje
    this.solution = map.respuesta
    this.cardId = map.tarjeta
    this.title = map.title
  }
}

export class QuestionOption{
  id: number
  option: string
  correct: boolean

  constructor(map){
    this.id = map.id
    this.option = map.opcion
    this.correct = map.correcta
  }
}