import { storeToRefs } from 'pinia'

import { useProfileStore } from '@/modules/profile/stores/profile.store'

const useProfile = () => {
  const { getProfile, getShifts, getBrandConfigurations, getUserStats, isBoss, getPermission, chileanCompany, companyCountry, holidayStats } = useProfileStore()
  const { status, profile, shifts, brand, stats } = storeToRefs(useProfileStore())

  return {
    status,
    profile,
    shifts,
    brand,
    stats,
    getProfile,
    getShifts,
    getBrandConfigurations,
    getUserStats,
    isBoss,
    getPermission,
    chileanCompany,
    companyCountry,
    holidayStats
  }
}

export default useProfile