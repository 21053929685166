export const PANEL_API_URL = window.location.host.includes('localhost') ? 'http://localhost:8000' : 'https://api.' + window.location.host
const API_HOST = window.location.host.includes('localhost') ? 'http://localhost:9000' : 'https://' + window.location.host
export const API_URL = `${PANEL_API_URL.replace('mi.','')}`
export const API_SASS = `${API_HOST.replace('mi.','')}`
export const API_MKP = `https://api.talana.${window.location.host.includes('localhost') || window.location.host.includes('qa') ? 'dev' : 'com'}`
export const NEW_WEBPOLL_URL = () => window.location.host.includes('localhost') ? 'http://localhost:5173/webpoll-climate' : `${BASE_API_SASS()}/services/do/#/webpoll-climate`

export const BASE_API_URL = () => {
    if(localStorage.getItem('API_URL') != undefined){
        return localStorage.getItem('API_URL')
    }
    return API_URL
}

export const BASE_API_SASS = () => {
    if(localStorage.getItem('API_SASS') != undefined){
        return localStorage.getItem('API_SASS')
    }    
    return API_SASS
}
