import { defineStore } from 'pinia'

import UserService from '@/services/user.service'
import WorkshiftsService from '@/services/workshifts.service'

import notify from '@/boot/notify-defaults'
import { requestStats } from '@/models/requests'

const profileObject = JSON.parse(localStorage.getItem('profile') as string) || {}

export const useProfileStore = defineStore({
  id: 'profile',
  state: () => ({
    status: false,
    profile: profileObject,
    shifts: {},
    brand: {},
    stats: null,
  }),
  actions: {
    getProfile(){
      return UserService.getProfile().then(
        profile => {
          this.status = true
          this.profile = profile
          return Promise.resolve(profile)
        },
        error => {
          this.status = false
          this.profile = null
          return Promise.reject(error)
        }
      )
    },
    getShifts(){
      WorkshiftsService.getShifts().then(
        ({data}) => {
          if(data){
            this.shifts = data
          }
        },
        () => {
          this.shifts = {}
        }
      )
    },
    async getBrandConfigurations(){
      const { data } = await UserService.getBrandConfigurations()
      if(data){
        this.brand = {
          logo: data.logo || false,
          primary: data.primario
        }
        return data
      }
    },
    async getUserStats(){
      UserService.getStats().then(
        ({data}) => {
          this.stats = new requestStats(data) || {}
        },
        () => {
          notify.sendNotification({
            message: 'Error al traer estadísticas, intente más tarde',
            type: 'error'
          })
        }
      )
    }
  },
  getters: {
    isBoss(state){
      return state.profile?.is_boss
    },
    getPermission: (state) => (permissionName:string) => {
      if(state.profile){
        const permissions = state.profile?.permisos as [{ nombre: string }]
        return permissions?.find(permission => permission.nombre == permissionName)
      }else{
        return null
      }
    },
    chileanCompany: (state): boolean => {
      return state.profile?.empresaDetails?.pais === 'CL'
    },
    companyCountry: (state): boolean => {
      return state.profile?.empresaDetails?.pais || 'CL'
    },
    holidayStats: (state) => {
      return state.stats?.estadisticasVacaciones || null
    },
  }
})