
export enum ConfigurationBusinessType {
  AppsLinks = 'Enlaces extras en menu',
  SMSSignVerification = 'Pedir sms de verificacion para firmar desde la app',
  ChangeUserPicture = 'Permitir subir y reemplazar foto personal',
  HalfDayHolidayRequest = 'Deshabilitar la solicitud de medio día de vacaciones en el portal del trabajador',
  HalfDayAdministrativeRequest = 'Deshabilitar la solicitud de medio día de dias administrativos en el portal del trabajador',
  HalfDayPermissionRequest = 'Deshabilitar la solicitud de medio día de dias de permiso de ausencia en el portal del trabajador',
  TypeDayHolidayRequest = 'Habilitar eleccion de vacaciones proporcionales o progresivas',
  HiddenTelephoneContact = 'Ocultar teléfono celular en los contactos del portal del trabajador',
  HiddenPersonalPhone = 'Ocultar teléfono personal de contactos en la app',
  HiddenHolidayRequest = 'Bloquear la solicitud de vacaciones en la app',
  HiddenExtraHoursRequest = 'Ocultar horas extra en la app',
  ShowDaysHolidays  = 'Mostrar dias de vacaciones/administrativos en mobile',
  HiddenHolidays = 'Ocultar dias de vacaciones',
  HiddenBirthdays = 'Ocultar cumpleaños de la empresa en la app',
  HiddenContacts = 'Ocultar tab de contactos en la app',
  HiddenReturnDayRequestHolidays = 'Ocultar fecha de retorno en solicitud de vacaciones',
  HiddenReturnDayRequestAdministrative = 'Ocultar fecha de retorno en solicitud de dias administrativos',
  AdministrativeDaysName = 'Nombre de días administrativos',
  HiddenReturnDayRequestPermission = 'Ocultar fecha de retorno en solicitud de permisos con goce o sin goce', 
  HiddenContactsMyTeam = 'Ocultar contactos en mi equipo en la app',
  HiddenReplacements = 'Ocultar remplazos en la app',
  HiddenMailContact = 'Ocultar e-mail en los contactos del portal del trabajador',
  HiddenAppDownloadLinks = 'Ocultar en Mi Talana enlaces de descarga de las apps',
  ConfigsRequestHolidaysAdministratives = 'Configuracion para solicitud de vacaciones y dias administrativos',
  ConfigsRequestPermissions = 'Configuracion para solicitud de permisos',
  ShowMotiveAdministrativeRequest = 'mostrar motivo de solicitud para dias administrativos',
  ShowAdministrativeDays =  'Mostrar dias administrativos',
  AllowFeedComments =  'Permitir comentarios en feed',
  ShowSettlements = 'Mostrar liquidaciones de mi equipo en la app',
  ShowPaySlipsAdvance = 'Mostrar liquidaciones de anticipo en la App',
  ShowDaysAdministrativeTeam = 'Mostrar saldo de vacaciones/administrativo de mi equipo',
  ShowHelpDesk = 'mostrar modulo de mesa de ayuda en la app',
  ShowDO = 'mostrar modulo de DO en la app',
  ShowMyTask = 'mostrar modulo de tareas en la app',
  ShowMarks = 'Mostrar marcas de asistencia en la web mobile',
  HiddenWorkingDayReport = 'Ocultar reporte de jornada',
  NotFound = 'NotFound',
  HideBenefitsTab = 'Ocultar Beneficios del menú de Mi Talana y apps',
  HideTutorialsTab = 'Ocultar Tutoriales del menú de Mi Talana y apps',
  HideEventsTab = 'Ocultar Eventos del menú de Mi Talana y apps',
  HideContactsTab = 'Ocultar Contactos del menú de Mi Talana y apps',
  showClubTalana = 'Tiene Club Talana',
  ShowWorkshiftCorrections = 'activar flujo de aprobacion de correciones de asistencia',
}

export class ConfigurationBusiness {
    id: number
    name: string
    value: string
    type: ConfigurationBusinessType
  
    // Default constructor will be called by mapper
    constructor(map) {
      this.id = map.id
      this.name = map.nombre == null ? map.name : map.name
      this.value = map.valor == null ? map.value : map.valor
      this.type = map.nombre == null ? map.type : map.nombre
    }

    dataValue(){
      return ['si', 'true', '1'].includes(this.value.toLowerCase())
    }
  }

export class EnabledServices {
  asistencia: boolean
  asistenciaMobile: boolean
  firmaDigital: boolean
  firmaDigitalMobile: boolean
  panelGerencial: boolean
  remuneraciones: boolean
  remuneracionesMobile: boolean
  reportesPersonalizados: boolean
  tratos: boolean
  comunicaciones: boolean
  do: boolean
  mesaDeAyuda: boolean
  solo_publicaciones?: boolean

  constructor(map) {
    this.asistencia = map.asistencia
    this.asistenciaMobile = map.asistenciaMobile
    this.firmaDigital = map.firmaDigital
    this.firmaDigitalMobile = map.firmaDigitalMobile
    this.panelGerencial = map.panelGerencial
    this.remuneraciones = map.remuneraciones
    this.remuneracionesMobile = map.remuneracionesMobile
    this.reportesPersonalizados = map.reportesPersonalizados
    this.tratos = map.tratos
    this.comunicaciones = map.comunicaciones
    this.do = map.do
    this.mesaDeAyuda = map.mesaDeAyuda
    this.solo_publicaciones = map.solo_publicaciones
  }
}