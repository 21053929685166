import axios from 'axios'
import apikeyHeader from './apikey-headers'
import { POSTS } from './urls'

class TermsService {
  getPendingTerms(){
    const url = `${POSTS()}/tyc/pending/`
    const config = {
      url: url,
      headers: apikeyHeader(),
    }
    return axios(config)
  }
  signTermsAndConditions(payload){
    const url = `${POSTS()}/tyc/${payload.id}/sign/`
    const config = {
      url: url,
      headers: apikeyHeader(),
      method: 'POST',
      data: payload.data
    }
    return axios(config)
  }
  getTermsList(){
    const url = `${POSTS()}/tyc/`
    const config = {
      url: url,
      headers: apikeyHeader(),
    }
    return axios(config)
  }
}

export default new TermsService()