import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './auth.store'
import { configurations } from './configurations.store'
import { requests } from './requests.store'
import climate from './climate'
import helpdesk from './helpdesk'
import feed from './feed'
import { benefits } from './benefits.store'
import { tutorials } from './tutorials.store'
import { events } from './events.store'
import { workshifts } from './workshifts.store'
import { termsAndConditions } from './terms.store'

export interface StoreInterface {
  // Define your own store structure, using submodules if needed
  // example: ExampleStateInterface;
  // Declared as unknown to avoid linting issue. Best to strongly type as per the line above.
  example: unknown;
}

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: true, // process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    configurations,
    climate,
    requests,
    helpdesk,
    feed,
    benefits,
    tutorials,
    events,
    workshifts,
    termsAndConditions
  },
  state: {},
  mutations: {}
})

export default store
export const useStore = () => store
