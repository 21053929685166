export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'))
  if (user && user.talana_token && user.kong_api_key) {
    return {
      'Authorization': 'Token ' + user.talana_token,
    }
  } else {
    return {}
  }
}
