import axios from 'axios'
import authHeader from './auth-header'
import { EMPLOYEE } from './urls'

class PayrollService {
  getPersonResume(id){
    return axios.get(`${EMPLOYEE()}/persons/${id}/resume/`, { withCredentials: true, headers: authHeader() })
  }
  getEnabledServices(){
    return axios.get(`${EMPLOYEE()}/enabled-services/`, { withCredentials: true, headers: authHeader() })
    .then(response => {
      if (response.data.myId) {
        localStorage.setItem('services', JSON.stringify(response.data))
      }
      return response.data
    })
  }
  getPeople(search = ''){
    let params = {
      fields: 'nombre,apellidoPaterno,apellidoMaterno,id,rut',
      page_size: 99999,
    }
    if(search){
      params = {...params, search: search}
    }
    const URL = `${EMPLOYEE()}/persons/`
    const config = {
      method: 'GET',
      url: URL,
      withCredentials: true,
      params: params,
      headers: authHeader()
    }
    return axios(config)
  }
}

export default new PayrollService()
